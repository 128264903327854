document.addEventListener("turbolinks:load", function() {
  auto_expand_text_areas();
});

function auto_expand_text_areas() {
  let text_areas = document.querySelectorAll('.auto-expand');

  text_areas.forEach(function(text_area){
    text_area.addEventListener('input', function(){
      resize(this)
    })

  resize(text_area)
  })
}

function resize (text_area) {
  if (text_area.scrollHeight > text_area.clientHeight) {
    text_area.style.height = text_area.scrollHeight+'px';
  }
}

function expand_text_areas_on_un_collapse () {
  document.querySelectorAll('.collapse-button').forEach(function(){
    this.addEventListener('click', function(){
      auto_expand_text_areas()
    })
  })
}
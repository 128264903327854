import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")

document.addEventListener("turbolinks:load", () => {
  flatpickr(".flatpickr", {
    enableTime: true,
    dateFormat: "Y-m-d",
    altInput: true,
    altFormat: "F J Y"
  })
});